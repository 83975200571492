// Import necessary modules from react-router-dom
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import QRScreen from './QRScreen';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';


// Set up your main App component with the Router and Route components

const App = () => {
  return (
    <MantineProvider>
    <BrowserRouter>
      <Routes>
        <Route path="q/:campaignId/*" element={<QRScreen/>} />
      </Routes>
    </BrowserRouter>
    </MantineProvider>
  );
};

export default App;
