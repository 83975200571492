import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {httpsCallable } from "firebase/functions";
import { Loader } from '@mantine/core';
import { auth, functions, storage } from './firebase';

function RatingScreen() {
  const { "*": params } = useParams();
  const paramArray = params ? params.split('/') : [];
  const { campaignId } = useParams();

  useEffect(() => {
    if (campaignId) {
      const fetchData = async () => {
        try {
          const handleCampaignRedirection = httpsCallable(functions, 'qrEndpoints-handleCampaignRedirection');
          const result = await handleCampaignRedirection({ campaignId, paramArray });
          const { link } = result.data;

          if (link) {
            window.location.href = link;
          }
        } catch (error) {
          console.error('Error fetching document: ', error);
        }
      };

      fetchData();
    }
  }, [campaignId, paramArray]);

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Loader color="gray" type="dots" size={40} />
    </div>
  );
}

export default RatingScreen;
